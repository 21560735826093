import React from 'react';
import { Grid, CardContent, TextField, makeStyles } from '@material-ui/core';
import { articleActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Description } from '@material-ui/icons';
import { AvatarIconWithText } from '../Shared';
import { Card } from './articlesStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4, 0, 2),
  }
}));

export function ArticleInnovationCard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { newArticle: { innovationQuestions } } = useSelector((state) => state.articlesReducer);

  function handleSetFields(e, index) {
    const { value } = e.target;
    dispatch(articleActions.setFieldsAnswer({ index, answer: value}));
  }

  return (
    <>
      {innovationQuestions && innovationQuestions.length > 0 && (
        <Grid item xs={12}>
          <Card elevation={0}>
            <AvatarIconWithText
              icon={<Description color="primary" />}
              text={t('detalhes').toLocaleLowerCase()}
            />
            <CardContent className={classes.content}>
              <Grid container spacing={3}>
                {innovationQuestions.map((item, index) => (
                  <Grid item xs={12} key={item._id}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      variant="filled"
                      id={item._id}
                      name="answer"
                      label={item.question}
                      defaultValue={item?.answer}
                      onBlur={(e) => handleSetFields(e, index)}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}
