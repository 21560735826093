import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import { history } from '../../helpers';
import ImagineSlider from '../Shared/ImagineSlider';
import { ArticleCard } from './ArticleCard';
import { ArticleNextArrow, ArticlePrevArrow } from './ArticleSliderOptions';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginTop: theme.spacing(2)
  },
  cardTitle: {
    color: theme.palette.neutrals.low.main,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function HighLightArticleList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const { articles, paging: { page, sort, searchText } } = useSelector(state => state.articlesReducer);

  useEffect(() => {
    dispatch(articleActions.getArticles(page, sort, 100, searchText));
  }, []);

  const articlesToRender = useMemo(() => {
    return articles.filter((({ status, highlight }) => status && highlight))
  }, [articles])

  return (<>
    {/* <Card className={classes.row} elevation={0}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.cardTitle}>
          {t('acesse_projetos_da_#InovaçãoAncar_no_nosso_mural')}
        </Typography>
      </Box>

      <Typography className={classes.textSeeAll} onClick={() => history.push('/articles')}>
        {t('ver_todas')}
      </Typography>
    </Card> */}
    <ImagineSlider
      settings={{
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: articlesToRender.length >= 3 ? 3 : articlesToRender.length,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 7000,
        nextArrow: <ArticleNextArrow />,
        prevArrow: <ArticlePrevArrow />
      }}
    >
      {articlesToRender.map((item, index) => (
        <ArticleCard
          key={index}
          title={item.title}
          image={item.thumbnail.url}
          link={`/article/detail/${item._id}`}
        />
      ))}
    </ImagineSlider>
  </>
  );
}

export { HighLightArticleList };
