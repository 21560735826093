export const articleConstants = {
  GET_ARTICLES_REQUEST: 'GET_ARTICLES_REQUEST',
  GET_ARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
  GET_ARTICLES_FAILURE: 'GET_ARTICLES_FAILURE',

  GET_MORE_ARTICLES_SUCCESS: 'GET_MORE_ARTICLES_SUCCESS',
  GET_MORE_ARTICLES_FAILURE: 'GET_MORE_ARTICLES_FAILURE',

  GET_ARTICLES_QUESTIONS_REQUEST: 'GET_ARTICLES_QUESTIONS_REQUEST',
  GET_ARTICLES_QUESTIONS_SUCCESS: 'GET_ARTICLES_QUESTIONS_SUCCESS',
  GET_ARTICLES_QUESTIONS_FAILURE: 'GET_ARTICLES_QUESTIONS_FAILURE',

  CLEAR: 'CLEAR',

  SET_ARTICLE: 'SET_ARTICLE',
  SET_PROP_ARTICLE: 'SET_PROP_ARTICLE',
  CREATE_ARTICLE_REQUEST: 'CREATE_ARTICLE_REQUEST',
  CREATE_ARTICLE_SUCCESS: 'CREATE_ARTICLE_SUCCESS',
  CREATE_ARTICLE_FAILURE: 'CREATE_ARTICLE_FAILURE',

  PUT_ARTICLE_REQUEST: 'PUT_ARTICLE_REQUEST',
  PUT_ARTICLE_SUCCESS: 'PUT_ARTICLE_SUCCESS',
  PUT_ARTICLE_FAILURE: 'PUT_ARTICLE_FAILURE',

  SET_ARTICLE_IMAGE: 'SET_ARTICLE_IMAGE',
  REMOVE_ARTICLE_IMAGE: 'REMOVE_ARTICLE_IMAGE',
  SET_IMAGE_TO_REMOVE_ARTICLE: 'SET_IMAGE_TO_REMOVE_ARTICLE',
  IMAGE_NEXT_CARD_ARTICLE: 'IMAGE_NEXT_CARD_ARTICLE',
  GET_ARTICLE_BY_ID_SUCCESS: 'GET_ARTICLE_BY_ID_SUCCESS',
  GET_ARTICLE_BY_ID_FAILURE: 'GET_ARTICLE_BY_ID_FAILURE',

  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  SET_ARTICLE_AUTHORS: 'SET_ARTICLE_AUTHORS',
  SET_ARTICLE_AUTHORS_EDIT: 'SET_ARTICLE_AUTHORS_EDIT',

  SET_ARTICLE_STATUS: 'SET_ARTICLE_STATUS',
  SET_ARTICLE_STATUS_EDIT: 'SET_ARTICLE_STATUS_EDIT',

  SET_ARTICLE_HIGHLIGHT: 'SET_ARTICLE_HIGHLIGHT',
  SET_ARTICLE_HIGHLIGHT_EDIT: 'SET_ARTICLE_HIGHLIGHT_EDIT',
  SET_ARTICLES_QUESTIONS_FIELD_ANSWER: 'SET_ARTICLES_QUESTIONS_FIELD_ANSWER',

  ARTICLE_LOAD_QUESTIONS: 'ARTICLE_LOAD_QUESTIONS',
  ARTICLE_LOAD_ARTICLES_DETAILS: 'ARTICLE_LOAD_ARTICLES_DETAILS',
  ARTICLE_TOGGLE_FILTER: 'ARTICLE_TOGGLE_FILTER'
};
