import {
  styled,
  Box,
  Grid,
  ButtonBase,
  Menu as MuiMenu,
  Card as MuiCard
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
  borderRadius: 8
}));

// FILTER
export const FilterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'  
});

export const FilterButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.high.light,
  borderRadius: 4,
  padding: theme.spacing(2),  
}));

export const Menu = styled(MuiMenu)({
  padding: 0,
  maxWidth: 328
});

export const MenuProps = {
  PaperProps: {
    style: {
      width: 200,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const MenuHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

export const MenuContent = styled(Box)(({ theme }) => ({
  width: 250,
  padding: theme.spacing(3, 2)
}));

export const FilterActions = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end'  
});