import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  makeStyles
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Search } from '../Shared';
import { articleActions } from '../../actions';
import { FilterContainer, FilterButton, Menu, MenuProps, MenuHeader, MenuContent, FilterActions } from './articlesStyles';

const useStyles = makeStyles((theme) => ({
  filterTextButton: {
    padding: theme.spacing(0, 1),
    color: theme.palette.neutrals.low.dark,
  },
  icon: {
    color: theme.palette.neutrals.low.dark,
  },
}));

export function ArticlesFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    selectedFilter,
    paging: { page, sort, limit, searchText }
  } = useSelector((state) => state.articlesReducer);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChangeSelect(e) {
    dispatch(articleActions.toggleArticlesSelected(e.target.value));
  }

  function searchInnovation(searchText) {
    dispatch(articleActions.getArticles(page, sort, limit, searchText));
  }

  function handleFilter() {
    let teste = selectedFilter === 'all' ? '' : selectedFilter;

    dispatch(articleActions.getArticles(page, sort, limit, searchText, teste));
    handleClose();
  }

  return (
    <FilterContainer>
      <Box>
        <FilterButton onClick={handleClick}>
          <FilterListIcon className={classes.icon} />
          <Typography variant="subtitle2" className={classes.filterTextButton}>
            {t('filtro')}
          </Typography>
          <ArrowDropDownIcon className={classes.icon} />
        </FilterButton>
        <Menu
          keepMounted
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          id="customized-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box display="flex" flexDirection="column">
            <MenuHeader>
              <Typography variant="body2">{t('filtrar_por')}</Typography>
            </MenuHeader>
            <Divider />
            <MenuContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel>Selecione</InputLabel>
                    <Select
                      autoWidth
                      defaultValue="all"
                      MenuProps={MenuProps}
                      value={selectedFilter}
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value="all">todos</MenuItem>
                      <MenuItem value="Lojista">Lojista</MenuItem>
                      <MenuItem value="Ancarense">Ancarense</MenuItem>
                      <MenuItem value="Consumidor">Consumidor</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <FilterActions item xs={12}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    startIcon={<FilterListIcon />}
                    onClick={handleFilter}
                  >
                    {t('filtrar')}
                  </Button>
                </FilterActions>
              </Grid>
            </MenuContent>
          </Box>
        </Menu>
      </Box>

      <Search
        query={searchInnovation}
        placeholder={t('pesquise_por_nome_da_equipe_ou_colaborador')}
      />
    </FilterContainer>
  );
}